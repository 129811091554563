import jsSHA from 'jssha';

function _getObject(data) {
    if (typeof data == 'string') {
        var tmpData = data.trim();
        if (tmpData.length <= 0) {
            return {};
        }
        try {
            return JSON.parse(data);
        } catch (e) {
            return {};
        }
    }
}

function calculateMac(text, key, algorithm) {
    var cleanText = text.replace(/\s+/g, "");

    var shaObj = new jsSHA(algorithm ? algorithm : "SHA-1", "TEXT");
    shaObj.setHMACKey(key, "TEXT");
    shaObj.update(cleanText);

    return shaObj.getHMAC("HEX");
}

/**
 * Calculates MAC of a map (sorts map first)
 * 
 * @param {*} requestObject 
 * @param {*} key 
 * @param {*} algorithm 
 */
const calculateRequestMac = (requestObject, key, algorithm) => {
    var text = "";
    Object.keys(requestObject).sort().forEach(function (element) {
        if (element != "requestType" && element != "invoicePDF" && requestObject[element] !== undefined && requestObject[element] !== null) {
            text += requestObject[element];
        }
    });

    return calculateMac(text, key, algorithm);
};

/**
 * Calculates MAC of a JSON object
 * 
 * @param {string|object} responseObject 
 * @param {string} key 
 * @param {string} algorithm  (default "SHA-1")
 */
const calculateResponseMac = (responseObject, key, algorithm) => {
    var dataAsJson = typeof responseObject == 'string' ? _getObject(responseObject) : responseObject;

    var dataAsString = JSON.stringify(dataAsJson);

    return calculateMac(dataAsString, key, algorithm);
};


export { calculateRequestMac, calculateResponseMac };