import ApiCommunicator from '../common/api_communicator';

class AmazonClient {
    constructor(shopPublicKey, configuration, initializationCompleteCallback, onButtonClickCallback) {
        console.log('AmazonClient::new');

        this._token = this.setToken(shopPublicKey);
        this._systemUrl = this.getValue(configuration, 'url', 'https://api.crefopay.de/secureFields/');
        this._buttonColor = this.getValue(configuration, 'buttonColor', 'Gold');
        this._placement = this.getValue(configuration, 'placement', 'Cart');
        this._checkoutLanguage = this.getValue(configuration, 'checkoutLanguage', 'en_GB');
        this._sandbox = this.getValue(configuration, 'sandbox', false);
        this._autoCapture = this.getValue(configuration, 'autoCapture', false);
        this._deliverySpecifications = this.getValue(configuration, 'deliverySpecifications');
        this._orderId = this.getValue(configuration, 'orderID');
        this._userId = this.getValue(configuration, 'userID');
        this._initializationCompleteCallback = initializationCompleteCallback;
        this._onButtonClickCallback = onButtonClickCallback;

        this._apiCommunicator = new ApiCommunicator({
            'MAC_HEADER': 'X-UPG-HMAC',
            'TOKEN_HEADER': 'X-UPG-TOKEN',
            'HMAC_ALGORITHM': 'SHA-512',
            'AMAZON_BUTTON': { url: this._systemUrl + 'amazonButton' }
        });
    }

    getValue(configuration, name, def) {
        var value = configuration && typeof configuration === 'object' ? configuration[name] : undefined;
        return value ? value : def;
    }

    checkIfArgumentIsValid(argument, errorMessage) {
        if (typeof (argument) === 'undefined' || argument === null || (argument instanceof String && argument.trim() === '')) {
            throw errorMessage === null ? "invalidArgument" : errorMessage;
        }
    }

    setToken(shopPublicKey) {
        this.checkIfArgumentIsValid(shopPublicKey, "shopPublicKey");
        return shopPublicKey;
    }

    //Public API

    /**
     * Method for creating and binding the pay-button.
     */
    createButton(...buttons) {
        console.log('AmazonClient::createButton');

        buttons = Array.isArray(buttons) && buttons.length ? buttons : ['amazonPayButton'];

        var request = {
            token: this._token,
            autoCapture: this._autoCapture,
            deliverySpecifications: this._deliverySpecifications,
            orderID: this._orderId,
            userID: this._userId
        };

        this._apiCommunicator.sendRequest('AMAZON_BUTTON', request, this._token).then((data) => {
            console.log('AmazonClient::createButton data:' + JSON.stringify(data));

            if (data.resultCode === 0) {
                buttons.forEach(button => {
                    var amazonPayButton = amazon.Pay.renderButton('div[data-crefopay=' + button + ']', {
                        merchantId: data.sellerId,
                        ledgerCurrency: 'EUR',
                        sandbox: this._sandbox,

                        // customize the buyer experience
                        checkoutLanguage: this._checkoutLanguage,
                        productType: 'PayAndShip',
                        placement: this._placement,
                        buttonColor: this._buttonColor
                    });

                    amazonPayButton.onClick(() => {
                        if (this._onButtonClickCallback) {
                            this._onButtonClickCallback(button);
                        }

                        amazonPayButton.initCheckout({
                            // configure Create Checkout Session request
                            createCheckoutSessionConfig: {
                                payloadJSON: data.payloadJSON,
                                signature: data.signature,
                                publicKeyId: data.publicKeyId
                            }
                        });
                    });
                });

                if (this._initializationCompleteCallback) {
                    this._initializationCompleteCallback(data);
                }
            }
        });
    }
}

export default AmazonClient;