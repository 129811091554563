import { calculateResponseMac } from '../common/mac_calculator';

class ApiCommunicator {

    constructor(apiUrlConfiguration) {
        this._apiConfiguration = this._setIfArgumentIsValid(apiUrlConfiguration);
    }

    _setIfArgumentIsValid(argument, errorMessage) {
        if (typeof (argument) == 'undefined' || argument === null) {
            throw errorMessage === null ? "invalidArgument" : errorMessage;
        }

        return argument;
    }

    _isResponseMacCorrect(headers, response, token) {
        var hMacAlgorithm = this._apiConfiguration['HMAC_ALGORITHM'];
        var macHeader = this._apiConfiguration['MAC_HEADER'];

        if (macHeader) {
            var responseMac = headers.get(macHeader);
            var expectedMac = calculateResponseMac(response, token, hMacAlgorithm);
            return responseMac === expectedMac;
        }

        return true;
    }

    _createErrorResponse(resultCode, message, serverResponse) {
        var response = { resultCode: resultCode, message: message };
        if (serverResponse) {
            response.details = serverResponse;
        }
        return response;
    }

    async sendRequest(requestType, request, token) {
        var requestUrl = this._apiConfiguration[requestType].url;
        var hMacAlgorithm = this._apiConfiguration['HMAC_ALGORITHM'];
        var requestData = JSON.stringify(request);

        var headers = {};
        headers['Content-Type'] = 'application/json';
        headers[this._apiConfiguration['MAC_HEADER']] = calculateResponseMac(requestData, token, hMacAlgorithm);
        headers[this._apiConfiguration['TOKEN_HEADER']] = token;

        return fetch(requestUrl, { method: 'POST', headers: headers, body: requestData })
            .then((response) => {
                if (response.status != 200 && response.status != 400) {
                    return this._createErrorResponse(5000, "communicationError", response);
                }

                return response.json()
                    .then((data) => {
                        if (this._isResponseMacCorrect(response.headers, data, token)) {
                            return data;
                        }
                        return this._createErrorResponse(5000, "invalidMac", response);
                    });
            });
    }
}

export default ApiCommunicator;